import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const Header = () => {
	const [date, setDate] = useState(Date.now());
	const [increment, setIncrement] = useState(100);

	setTimeout(() => setIncrement(10), 5000);
	setTimeout(() => setIncrement(1), 10000);

	useEffect(() => {
		const timer = setInterval(() => setDate(Date.now()), increment);
		return function cleanup() {
			clearInterval(timer);
		};
	});

	const andrewAgeCalc = () => {
		const diff_ms = Date.now() - new Date(2004, 0, 15).getTime();
		const age_dt = new Date(diff_ms);
		const age = Math.abs(age_dt.getUTCFullYear() - 1970);
		const yearOne = 15;
		const yearTwo = 9;
		return { dog: age, human: (age - 2) * 5 + yearOne + yearTwo };
	};

	return (
		<>
			<div className="fr w-100 w-80-l">
				{/* <p className="f6 near-black">Kirby Labs</p> */}
				<h1 className="f2 f1-l lh-title mt0 mb4 mb5-ns">
					The moment is
					<br className="dn db-ns " /> <span className="dark-red">{date}</span>
					<div className="f7 ">
						THE FUTURE IS NOW
						<span className="near-black"> and you are dying</span>
					</div>
				</h1>
			</div>
			<div className="f6 lh-copy fl w-100 mb4">

		{/* 
				 <div className="fl-ns w-100 w-20-l pr3-m pr5-l ">
				 	<p>
				 		New York <small className="fw6 dark-red">is dead</small>
				 	</p>
				 </div>


				<div className="fl-ns w-50-m w-20-l pr3-m pr5-l ">
					<Link to="/adepta" className="link white hover-blue  tracked">
						<p>
							ADEPTA
							<span className="white">A</span>
							<span className="dark-red">D</span>
							<span className="white">E</span>
							<span className="dark-red">P</span>
							<span className="white">T</span>
							<span className="dark-red">A</span>

						</p>
					</Link>
				</div>
 */}

				<div className="fl-ns w-50-m w-20-l pr3-m pr5-l">
					<a
						href="https://www.deathonwax.com"
						className="link washed-yellow  hover-purple"
					>
						<p>Death on Wax</p>
					</a>
				</div>
				<div className="fl-ns w-50-m w-20-l pr3-m pr5-l">
					<a
						href="https://www.fortherecordbook.net"
						className="link hover-dark-green   washed-yellow "
					>
						<p className="">
							For the
							<br className="dn db-l" /> RECORD BOOK
						</p>
					</a>
				</div>

							<div className="fl-ns w-50-m w-20-l pr3-m pr5-l">
					<a
						href="https://houseundermagic.com"
						className="link washed-yellow  hover-hot-pink"
					>
						<p>HOUSE/UNDER/MAGIC</p>
					</a>
				</div>

								<div className="fl-ns w-50-m w-20-l pr3-m pr5-l">
					<a
						href="https://industrystandard.audio"
						className="link washed-yellow  hover-blue"
					>
						<p>Industry Standard</p>
					</a>
				</div>



				<div className="fl-ns w-50-m w-20-l pr3-m pr5-l">
					<div>
						<span className="yellow">Andrew Walter Status: </span>{" "}
						<span className="dark-red">DEAD</span>
					</div>
					<span className="">DOG: </span>
					<span className="">20</span>
					<br />
					<span className="">HUMAN: ≈114</span>
				</div>
			</div>
		</>
	);
};
