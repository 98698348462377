import naturalCompare from "string-natural-compare";

export const Adepta = () => {
	const images = importAll(require.context("./data/deck", false, /\.(png|jpe?g|svg)$/));

	function importAll(r) {
		let images = [];
		r.keys().map((item, index) => {
			const value = item.split("./").join("");
			images.push(value);
		});
		return images.sort(naturalCompare);
	}

	const renderDeck = () => {
		return images.map((slide, index) => {
			return <img src={require(`./data/deck/${slide}`)} alt="NOT WORKING" key={index} />;
		});
	};

	return (
		<>
			{/* <Link className="link" to="/">
				<div className="f1 pb3 tc  washed-yellow  grow">GO HOME</div>
			</Link> */}
			<a
				href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ1fqe9nB13UbjEirrI-iJFTva8HdbmkT_PQZLHkeqo_aPs27W14c7Pr7ufZSxcWmXu__Nxv2fgK2Bf/pubhtml"
				className="link yellow hover-dark-red f2-ns f3"
				target="_blank"
				rel="noreferrer"
			>
				<div className="tc mb3 ba br3 pv3 w-50-ns center b--dark-red grow hover-bg-yellow hover-dark-red ">
					{">"} Live Financial Model {"<"}
				</div>
			</a>
			<div className="tc">{renderDeck()}</div>
		</>
	);
};
