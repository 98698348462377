import { Route, Switch } from "react-router-dom";
import { Header } from "./components/Header";
import { Adepta } from "./routes/Adepta/Adepta";
import { ArkHive } from "./routes/Arkhive/ArkHive";
// import { BDAY } from "./routes/bday/BDAY";
// import { t26 } from "./routes/bday/t26";
// import { t27 } from "./routes/bday/t27";
// import { t28 } from "./routes/bday/t28";
import homeImg from "./media/images/caeser.png";
// import homeVid from "./media/video/anyway.mp4";

// const { REACT_APP_BASE_URL } = process.env;

export const App = () => {
  return (
    <main className="cf pa2 pa3-m pa4-l mw9 center	">
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route path="/adepta" component={Adepta} />
        <Route path="/arkhive" component={ArkHive} />
        {/* <Route path="/3030" component={BDAY} />
				<Route path="/26" component={t26} />
				<Route path="/27" component={t27} />
				<Route path="/28" component={t28} /> */}
        <Route
          render={() => (
            <h1 className="tc dark-red">
              404 not found, stop fuckin a'round retard
            </h1>
          )}
        />
      </Switch>
    </main>
  );
};

const Homepage = () => {
  return (
    <>
      <Header />
      {/* <video src={homeVid} className="db" controls loop /> */}

      <img src={homeImg} className="db" alt="iceland" />
      <div className="tc pt1 f7 dark-gray">© {new Date().getFullYear()}</div>
    </>
  );
};

{
  /*<h1>IF YOU ARE COMING BACK TO FIND ME</h1>
			<h2>you better have good aim</h2>
	 <h3 className="f5 gold">
					here is an aide to your{" "}
					<a
						href="https://www.youtube-nocookie.com/embed/KU13c6cUb28?playlist=KU13c6cUb28&autoplay=1&iv_load_policy=3&loop=1&modestbranding=1&start="
						className="link gold hover-hot-pink"
					>
						minute of angle
					</a>{" "}
				</h3>
				<h4 className="f6 dark-red">
					You can usually find me off of the Jacqueline Kennedy Onassis walking my
					dog, Andrew Walter
				</h4>
				<h5 className="f7 pb5">
					<span className="yellow">Andrew Status:</span>
					<span className="green"> Alive</span>
				</h5>
			</div> */
}
